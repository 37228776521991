/* Preloader styles */
.preloader {
    background-image: url('./Predictify-2.png'); /* Use the correct path */
    background-size: cover;        /* Fill the screen */
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;  /* Keeps it in place when scrolling */
    min-height: 100vh;
  
    display: flex;                /* Use flexbox to center content */
    justify-content: center;      /* Align horizontally */
    align-items: center;          /* Align vertically */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3); /* Light border */
    border-top: 5px solid #fff; /* Animated border */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  