.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.responsive-card {
  min-height: 430px;
  max-height: 430px;
}

@media (max-width: 768px) {
  .responsive-card {
    min-height: 370px;
    max-height: 370px;
  }
}

@media (max-width: 1024px) {
  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.transition-all {
  transition: all 0.3s ease-in-out;
}

.btn-outline-purple {
  border-color: purple;
  background-color: green;
}

/* .nav-item{
  border-color: #00FF00;
} */

.App-header {
  background-color: #222427;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-headerV2 {
  background-color: #222427;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}
.text-orange {
  color: orange;
}

.matchweek-dropdown {
  max-height: 200px; /* Adjust max-height as needed */
  overflow-y: auto;
}

.bg-warning {
  background-color: yellow; /* Change to your desired color */
}
.highlight-row {
  background-color: rgba(255, 255, 0, 0.5) !important;
}

/* global leaderboard style   */

.custom-table {
  border-radius: 8px;
  margin-top: 20px;
}



.table tbody tr:nth-child(even) {
  background-color: #f3e5f5; /* Light purple */
}

.table tbody tr:hover {
  background-color: #e1bee7; /* Slightly darker light purple on hover */
}



.btn-purple:hover {
  background-color: #732d91; /* Darker purple on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Button shadow effect */
}





/* Styles for the current user's row background */
.current-user-row {
  background-color: #fffb01 !important; /* Light purple for entire row */
}

/* Specific style to override the text color within the row */
.current-user {
  background-color: #c79abd !important; /* Red or your preferred color for username */
  font-weight: bold; /* Keep the bold styling */
  color: #00FF00 !important;
}

/* Optional: Add extra emphasis for the current user text */
.current-user-row .current-user {
  color:  #00FF00 !important; /* Enforce the color */
  font-weight: bold;
}

.border-custom {
  position: relative;
  border: 3px solid purple; /* Solid purple border */
  z-index: 1; /* Ensure it stays above the background */
  border-radius: 8px; /* Optional: Add border-radius if needed */

}

.border-custom::before {
  content: '';
  position: absolute;
  /* top: -3px; 
  left: -3px; 
  right: -3px; 
  bottom: -3px;  */
  border-radius: 8px; /* Match the border-radius of the card */
  border: 3px solid transparent; /* Transparent border for positioning */
  background: linear-gradient(90deg, rgba(138, 14, 138, 0.8), rgba(255, 0, 255, 0.8), rgba(138, 14, 138, 0.8));
  background-size: 200% 200%; /* Double the size for animation */
  z-index: -1; /* Behind the card content */
  animation: rotateBorder 5s linear infinite, twinkleBorder 2s ease-in-out infinite; /* Combined animations */
}

@keyframes rotateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}








.btn-purple-leaderboard {
  background-color: rgb(138, 14, 138) !important;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
}

  /* global leaderboard style   */


.matchweek-dropdown::-webkit-scrollbar {
  width: 5px;
}

.matchweek-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.matchweek-dropdown::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.matchweek-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Instagram CSS Linear Gradient Background */
.gradient {
  background: #833ab4;
  background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
}

.instagram-icon {
  width: 23px;
  height: 23px;
  fill: url(#instagramGradient);
}
.bg-warning {
  background-color: #ffc107; /* Example background color */
  font-weight: bold; /* Example font-weight */
}


.highlight-row {
  background-color: #ffffcc; /* Example highlight color */
  font-weight: bold; /* Example: make the text bold */
}

.bg-dashed-orange-black {
  background: repeating-linear-gradient(
    45deg,
    #F94D00,        /* Tangelo color */
    #F94D00 10px,   /* End color and width of the first stripe */
    black 10px,     /* Start color of the second stripe */
    black 20px      /* End color and width of the second stripe */
  );
  color: white;     /* Text color */
}
.text-bg-babyblue {
  background-color: rgb(105, 192, 241);
}

.App-headerV3 {
background-color: #f0f2f5;
  min-height: 90vh;
}
.App-headerV8 {
  background-color: #f0f2f5;
  min-height: 90vh;
}
.App-headerV9 {
  background-color: #25085c;
}
.Nav-headerV8 {
  background-color: #fff;
}

.App-headerV4 {
  background-color: #222427;
  min-height: 90vh;
padding-top: 5%;
}
.App-headerV5 {
  min-height: 90vh;
  background-color: #f8f9fa;
}
.App-headerV6 {
  min-height: 100vh;
  background-color: #222427;
}
.App-headerV7 {
  min-height: 100vh;
  background-color: #f8f9fa;
}
.App-headerV8 {
  background-image: url('./Predictify-2.png'); /* Use the correct path */
  background-size: cover;        /* Fill the screen */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;  /* Keeps it in place when scrolling */
  min-height: 100vh;
}

/* For mobile-specific adjustments */
@media (max-width: 768px) { /* Adjust for smaller screens */
  .App-headerV8 {
    background-size: cover; /* Maintain height at 100% of the viewport, adjust width proportionally */
    background-position: center; /* Focus on the top-left corner */
    background-repeat: no-repeat; /* Ensure no repeating */
    background-attachment: fixed;  /* Keeps it in place when scrolling */

  }
}


/* Custom styles for tabs */
.custom-tab {
  background-color: transparent; /* Make sure the background is transparent initially */
  transition: background-color 0.3s ease; /* Smooth transition effect for background color */
}

/* Active tab: White background, dark text */
.nav-tabs .nav-link.active {
  background-color: white !important;
  color: #333 !important; /* Dark text for active tab */
  border-color: #dee2e6 #dee2e6 #fff; /* Border for active tab */
}

/* Inactive tabs: Dark background, white text */
.nav-tabs .nav-link {
  color: white !important; /* White text for inactive tabs */
}






/* .App-headerV8 {
  background-image: url('./Predictify-2.png');
  background-size: cover;        
  background-position: center;   
  background-repeat: no-repeat;  
  min-height: 100vh;
} */

.mb-3 .form-control:focus {
  box-shadow: 0 0 1px #13a863; 
}

.form-select:focus {
  box-shadow: 0 0 1px #13a863; 

}
.bg-orange { 
  background-color: rgb(255, 72, 5);
}
.table-purple { 
  background-color: #833ab4 !important;
}

.table-striped-primary tbody tr:nth-of-type(odd) {
  background-color: #dd2697; /* Bootstrap primary color or your defined primary */
  color: white;
}

/* Apply transparent background to the entire table */
.transparent-table {
  background-color: transparent !important;
}

.transparent-table th,
.transparent-table td {
  background-color: transparent !important;
}

/* Remove Bootstrap striping by setting even and odd rows to transparent */
.transparent-table tbody tr:nth-child(even) {
  background-color: transparent !important;
}

.transparent-table tbody tr:nth-child(odd) {
  background-color: transparent !important;
}

.btn-current-round {
  border: 2px solid blue; /* Adjust the border size and color as needed */
}



/* Default style for the team column on smaller screens */
.team-column {
  width: 40%; /* width for smaller screens */
}
.table-striped-custom {
  color: #732d91;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .team-column {
    width: 15%; /* width for larger screens */
  }
}


/*EURO 2024 standings*/

.container-1 {
  display: flex;
  justify-content: center;
}






.grid {
  display: grid;
  grid-template-columns: repeat(4, 205px);
  align-items: center;
}

.justify-space-around {
  display: grid;
  align-content: space-around;
  min-height: 100%;
}

.round {
  position: relative;
  max-width: 185px;
}

.match {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 80px;
  width: 180px;
  margin: 10px auto;
  background-color: white;
  border: 1px solid black;
}

.link {
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 100%;
  right: -10px;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.link:after {
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  left: 10px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid red;
}

/*EURO 2024 standings*/
/*bracket css*/

/*bracket css*/

.padtopinput {
  padding-top: 25px;
}

.input-error {
  border-color: rgb(112, 112, 112);
}
.active-button {
  background-color: black;
  color: white;
}
.text-light-hover {
  color: white;
}
.text-light-hover:hover {
  color: #222427;
}

.form-control:focus {
  box-shadow: 0 0 2px  #13a863;

}
.form-check-input:focus {
  box-shadow: 0 0 1px #13a863; /* Customize the box shadow for focus state */
}

.accordion-button:focus {
  box-shadow: 0 0 3px #13a863; /* Customize the box shadow for focus state */
}
.dropdown-menu.custom {
  max-height: 200px; /* Set a maximum height for the dropdown menu */
  overflow-y: auto; /* Enable vertical scrolling */
}

.dropdown-menu-inner {
  overflow-y: auto; /* Enable vertical scrolling for inner content */
}


.mb-3 {
  border-radius: 10px
}
.dark {
  background-color: #222427;
}
.App-link {
  color: #61dafb;
}
.btn-info{
  margin-top: 0.5rem
}

.form-control me-2{
  box-shadow: 0 0 1px #13a863; 

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.default-profile-picture {
  width: 100px; 
  height: 100px; 
  border-radius: 50%; 
  object-fit: cover; 
}

.avatar{
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-width: 3px;
  border-color: #3b5781;
  border-style: outset;
}
.padtop{
  padding-top: 5px
}
.padbot{
  padding-bottom: 5px
}
.padtop1 {
  padding-top: 10px;
}
.padtopbot {
  padding-bottom: 10px;
  padding-top: 10px
}

.form-control {
  border-color: #e6eeff;
}
.custom.show a:hover {
  background-color:  rgb(138, 14, 138) !important;
  color: #fff;

}

.text-purple {
  color:  rgb(138, 14, 138) !important;
}
.border-purple{
  color:  rgb(138, 14, 138) !important;
  color: #8a0ea0;

}
.homehover:hover {
  text-decoration: underline;}

.bg-outline-purple {
  border-color:  rgb(138, 14, 138) !important;

}

.navhover:hover{
  color:  rgb(138, 14, 138) !important;

}

.custom-bg-purple {
  color: black; /* Ensure text is readable on the purple background */
}

.custom-bg-purple:hover,
.custom-bg-purple:focus,
.custom-bg-purple:active {
  background-color: rgb(138, 14, 138) !important;
  color: #fff;
}


.btn-custom,
.btn-custom:hover {
  background-color: rgb(138, 14, 138) !important;
  color: #fff;
}
.rotate-on-hover:hover {
  transform: rotateY(180deg); /* Rotate horizontally (left-to-right) */
}
.Arsenal {
  transition: transform 0.7s ease; /* Add a transition for the transform property with a duration of 0.3 seconds and easing */
}
.placeholder-white::placeholder {
  color: white;
}

.Arsenal:hover {
  /* transform: scale(1.14);  */
  transform: rotateY(180deg); 
}

.hover:hover {
  border-color: #e01414; /* Change to the desired background color */
}

/* Define the colors for Aston Villa Home Kit */
.astonvillakithome .card-header {
  background-color: rgb(127, 0, 0); /* Claret (Deep Red) */
  color: white; /* Text color */
}

.text-bg-astonvillakithome  {
  background-color: rgb(127, 0, 0); /* Claret (Deep Red) */
  color: white; /* Text color */
}
.bournemouthkithome .card-header {
  background: red;
    color: rgb(0, 0, 0); /* Text color */
  }
  .bournemouthkitaway .card-header {
    background: rgb(83, 193, 226);
      color: rgb(255, 255, 255); /* Text color */
    }
    .chelseakitaway .card-header{
      background: #044099;
      color: rgb(255, 255, 255); /* Text color */

    }
    .crystalpalacehomekit .card-header {
      background: linear-gradient(to right, red 50%, blue 50%);
      color: white; /* Text color */
    }
    .FulhamKitAway .card-header {
      background: hotpink;
      color: white; /* Text color */
    }
.text-bg-lutonhomekit{
  background: orangered;
  color: white;
}    
.mancitykithome .card-header {
  background: lightblue;
  color: white;
}
.manunitedawaykit .card-header {
  background: darkgreen;
  color: white;
}
.tottenhamkitaway .card-header {
  background: darkblue;
  color: white;
}

.Liverpoolawaykit .card-header {
  background: lightgreen;
  color: rgb(0, 0, 0);
}
.padtopstats {
  padding-top: 25px;
}
.purple-bg {
  background-color: rgb(138, 14, 138) !important;
}
.border-purple {
  color: rgb(138, 14, 138) !important;
}
.btn-purple {
  color: white;
  background-color: rgb(138, 14, 138) !important;
}

.btn-purple-outline {
  color: white;
  border-color: rgb(138, 14, 138) !important;
}
.purple1 {
  color: rgb(218, 24, 218) !important;

}
.btn-purple:hover {
  color: white; /* Maintain white text color on hover */
  background-color: rgb(138, 14, 138) !important;
}
.btn-outline-purple {
  color: white;
  background-color: rgb(138, 14, 138)!important;
}

.react-tel-input .flag-dropdown {
  background: transparent !important;
}

.react-tel-input .selected-flag {
  background: transparent !important;
}

.react-tel-input .country-list {
  background: transparent !important;
}

.rounds-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 85%;
  scroll-behavior: smooth; /* Keep smooth scrolling */
  
  /* Hide scrollbar on different browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.rounds-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.btn-outline-purple-1{
  border-color: #8a0ea0;
  background-color: transparent;
}

/* styles.css */
.wave-text {
  font-family: 'Lato', sans-serif;
  position: relative;
  animation: waveEffect 2s infinite; /* Apply the wave effect animation */
}

@keyframes waveEffect {
  0% {
    text-shadow: 0 0 5px rgba(138, 14, 138, 1), 0 0 10px rgba(138, 14, 138, 1), 0 0 15px rgba(138, 14, 138, 1);
  }
  50% {
    text-shadow: 0 0 10px rgba(138, 14, 138, 1), 0 0 30px rgba(138, 14, 138, 1), 0 0 40px rgba(138, 14, 138, 1);
  }
  100% {
    text-shadow: 0 0 5px rgba(138, 14, 138, 1), 0 0 10px rgba(138, 14, 138, 1), 0 0 15px rgba(138, 14, 138, 1);
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.custom-hover:hover {
  color: #6f42c1; /* Purple color on hover (you can adjust the shade) */
}

@media (max-width: 768px) {
  .mobile-adjust::before {
    content: "Pick";
  }
}

@media (min-width: 769px) {
  .mobile-adjust::before {
    content: "No pick";
  }
}


/* Ensure the background of the table headers is transparent */
th.bg-transparent {
  background-color: transparent !important; /* Ensure background is fully transparent */
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6); /* Light placeholder text */
}

.form-control::placeholder {
  color: white !important; /* Ensure the placeholder text color is white */
}

input:-webkit-autofill {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;  /* Ensures the border is kept */
}

/* Autofill label color */
input:-webkit-autofill + label {
  color: rgb(83, 76, 76) !important;
}

/* Make sure to add this CSS in your stylesheet */

.snake-border-btn {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  transition: color 0.3s ease;
}

.snake-border-btn:hover {
  color: #1779ff;
}

.snake-border-btn span {
  position: absolute;
  display: block;
  background: linear-gradient(to right, transparent, #e20b0b);
}

.snake-border-btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-top 2s linear infinite;
}

.snake-border-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-right 2s linear infinite;
  animation-delay: 0.5s;
}

.snake-border-btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-bottom 2s linear infinite;
  animation-delay: 1s;
}

.snake-border-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-left 2s linear infinite;
  animation-delay: 1.5s;
}

/* Gold snake btn */

.snake-border-btn-gold {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  transition: color 0.3s ease;
}

.snake-border-btn-gold:hover {
  color: #fdd41a;
}

.snake-border-btn-gold span {
  position: absolute;
  display: block;
  background: linear-gradient(to right, transparent, #f3cf06);
}

.snake-border-btn-gold span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-top 2s linear infinite;
}

.snake-border-btn-gold span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-right 2s linear infinite;
  animation-delay: 0.5s;
}

.snake-border-btn-gold span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-bottom 2s linear infinite;
  animation-delay: 1s;
}

.snake-border-btn-gold span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-left 2s linear infinite;
  animation-delay: 1.5s;
}
/* GOld button */

/* Blue snake btn */

.snake-border-btn-primary {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  transition: color 0.3s ease;
}

.snake-border-btn-primary:hover {
  color: #fdd41a;
}

.snake-border-btn-primary span {
  position: absolute;
  display: block;
  background: linear-gradient(to right, transparent, #062af3);
}

.snake-border-btn-primary span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-top 2s linear infinite;
}

.snake-border-btn-primary span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-right 2s linear infinite;
  animation-delay: 0.5s;
}

.snake-border-btn-primary span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-bottom 2s linear infinite;
  animation-delay: 1s;
}

.snake-border-btn-primary span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-left 2s linear infinite;
  animation-delay: 1.5s;
}
/* Blue button */

/* Info snake btn */

.snake-border-btn-info {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  transition: color 0.3s ease;
}

.snake-border-btn-info:hover {
  color: #fdd41a;
}

.snake-border-btn-info span {
  position: absolute;
  display: block;
  background: linear-gradient(to right, transparent, #1fd7e4);
}

.snake-border-btn-info span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-top 2s linear infinite;
}

.snake-border-btn-info span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-right 2s linear infinite;
  animation-delay: 0.5s;
}

.snake-border-btn-info span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-bottom 2s linear infinite;
  animation-delay: 1s;
}

.snake-border-btn-info span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-left 2s linear infinite;
  animation-delay: 1.5s;
}
/* Info button */

.snake-border-btn-2 {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  transition: color 0.3s ease;
}

.snake-border-btn-2:hover {
  color: #2aff17;
  background-color: #53bb4a;;

}

.snake-border-btn-2 span {
  position: absolute;
  display: block;
  background: linear-gradient(to right, transparent, #24e20b);
}

.snake-border-btn-2 span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-top 2s linear infinite;
}

.snake-border-btn-2 span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-right 2s linear infinite;
  animation-delay: 0.5s;
}

.snake-border-btn-2 span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  animation: snake-border-bottom 2s linear infinite;
  animation-delay: 1s;
}

.snake-border-btn-2 span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  animation: snake-border-left 2s linear infinite;
  animation-delay: 1.5s;
}

@keyframes snake-border-top {
  0% {
    left: -100%;
  }
  50%, 100% {
    left: 100%;
  }
}

@keyframes snake-border-right {
  0% {
    top: -100%;
  }
  50%, 100% {
    top: 100%;
  }
}

@keyframes snake-border-bottom {
  0% {
    right: -100%;
  }
  50%, 100% {
    right: 100%;
  }
}

@keyframes snake-border-left {
  0% {
    bottom: -100%;
  }
  50%, 100% {
    bottom: 100%;
  }
}


input[type="file"]::-webkit-file-upload-button {
  background-color: transparent !important;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
}

#passreset::placeholder {
  color: black !important; /* Set the placeholder text color to dark */
  opacity: 1; /* Ensure the placeholder text is fully opaque */
}


.swiper-button-next {
  right: 10px;
  background-color: transparent;
  color: white;
  border-color: white;
}

.swiper-button-prev {
  right: 10px;
  background-color: transparent;
  color: white;
  border-color: white;
}

.swiper-button-next, .swiper-button-prev {
  width: 40px;
  height: 40px;
  background-color: transparent !important;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  /* border: 1px solid white;  */
  color: white !important; /* Ensures the button's text color is white */
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the arrow icon */
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 1.5rem; /* Adjust the size of the arrows */
  color: white !important; /* Ensures the arrows are white */
  content: '\2192'; /* Arrow symbols (→ and ←) */
}

.text-bg-orange {
  background-color: rgb(233, 155, 10);
}

.hover-underline:hover {
  text-decoration: underline;
}

.highlight {
  border: 2px solid red;
}

.flash-red {
  animation: flash 0.5s infinite alternate;
}

@keyframes flash {
  0% {
      background-color: rgba(255, 0, 0, 0.3);
  }
  100% {
      background-color: transparent;
  }
}


/* .nav-link.active {
  border-bottom: 0.5px solid blue; 
} */

/* @media (max-width: 767px) {
  .col.text-end {
    display: grid;
  }

  .col.text-end img {
    grid-row: 1;
    justify-self: center;
  }

  .col.text-end span {
    grid-row: 2;
  }
} */

/* @media (min-width: 778px) {
  .card:hover{
    transform: scale(1.005); 

  }
} */