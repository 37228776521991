/* Custom Swiper navigation button styles */
.swiper-button-next,
.swiper-button-prev {
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  background-color: rgba(255, 255, 255, 0.7); /* Optional: add a background */
  border-radius: 50%; /* Optional: make it circular */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.swiper-button-next {
  right: 10px; /* Distance from the right edge */
}

.swiper-button-prev {
  left: 10px; /* Distance from the left edge */
}

/* Optional: Change color of the arrows */
.swiper-button-next::after,
.swiper-button-prev::after {
  color: #000; /* Change arrow color */
  font-size: 18px; /* Change arrow size */
}
